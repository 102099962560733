import { useNavigate } from 'react-router-dom';

import Btn from '@/components/Btn';
import QRCodeComponent from '@/components/QRCodeComponent';
import { useEffect } from 'react';
import PrintReceipt from '../print/PrintReceipt';

import { BsFillHeartPulseFill } from "react-icons/bs";

type CheckoutOrderNumberModalProps = {
  orderNumber: number;
  onSuccess?: () => void;
  order: Api.Order;
  isPrinting?: boolean;
};

function CheckoutOrderNumberModal({ orderNumber, onSuccess, order, isPrinting }: CheckoutOrderNumberModalProps) {
  const navigate = useNavigate();

  const windowWithElectron = window as WindowWithElectron;

  useEffect(() => {
    if (isPrinting && windowWithElectron?.electronAPI?.printReceipt) {
      windowWithElectron?.electronAPI.printReceipt(order);
    }
  }, []);

  const handleContinue = () => {
    if(onSuccess){
      onSuccess();
      return;
    }
    navigate('/');
  }

  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none print:hidden"
      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                ¡Gracias por tu compra!
              </h3>
            </div>
            <div className="relative p-6 flex-auto">
              <p className='flex items-center justify-center text-center gap-2 mb-3'>
                Cuidamos el planeta
              <BsFillHeartPulseFill className='text-xl text-green'/>
              </p>
              <p className='text-center font-bold'>Escanea para obtener tu ticket!</p>
              <div className='text-center'>
                <QRCodeComponent id={order.orderQrId} />
              </div>
              <p className="my-4 font-bold text-lg leading-relaxed text-center">
                Te llamaremos con tu número de pedido:
              </p>
              <div className="text-primary text-5xl text-center">{orderNumber}</div>
              <div className="text-primary text-xl text-center font-black">
                ¡RECUERDALO!
              </div>
            </div>
              <div className="flex items-center justify-center py-6 border-t border-solid border-blueGray-200 rounded-b">
                <Btn onClick={handleContinue}>
                  Continuar
                </Btn>
              </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
      <PrintReceipt order={order} isPrinting={true} />
    </>
  );
}

export default CheckoutOrderNumberModal;
