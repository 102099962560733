import { useState } from 'react';

import { CgSpinnerTwoAlt } from 'react-icons/cg';

import env from '@/utils/constants';

const QRCodeComponent = ({ id }: { id: string }) => {
  const [loading, setLoading] = useState(true);

  return (
    <div className='text-center grid place-items-center min-h-[200px]'>

      {loading && <CgSpinnerTwoAlt className="animate-spin text-4xl" />}

      <img
        src={`${env.VITE_API_URL}/orders/${id}/qr`}
        alt="QR Code"
        onLoad={() => {
          setLoading(false);
        }} // Hide loader when loaded
        onError={() => {
          setLoading(false);
        }} // Hide loader even if there's an error
        style={{
          display: loading ? 'none' : 'block',
          width: '200px',
          height: '200px',
        }}
      />
    </div>
  );
};

export default QRCodeComponent;
